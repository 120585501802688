const apiCalls = {
  loginUser: "auth/login",
  logoutUser: "auth/logout",
  loginChangePassword: "auth/loginChangePassword",
  MenuList: "menus",
  Settings: "settings",
  LocationImagePath: "uploads?uploadWhileCreate=true&uploadPath=location",
  ProfileImagePath: "uploads?uploadWhileCreate=true&uploadPath=employee",
  dashboard: "dashboard/counts",
  Uploads: "bulkuploadStatus",
  employees: "employees",
  myProperties: "myProperties",
  clients: "clients",
  agents: "agents",
};

// {"loginUser":"auth/login","logoutUser":"auth/logout","loginChangePassword":"auth/loginChangePassword","MenuList":"menus","Settings":"settings","LocationImagePath":"uploads?uploadWhileCreate=true&uploadPath=location","ProfileImagePath":"uploads?uploadWhileCreate=true&uploadPath=employee","dashboard":"dashboard/counts","employee":"employees","employees":"employees","Uploads":"bulkuploadStatus"}

export default apiCalls;
