import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MyProperties from './components/MyProperties';

const myProperties = (props,{ t }) => (
  <Container>
    <MyProperties {...props} />
  </Container>
);

myProperties.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(myProperties);
