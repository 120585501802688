import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import CheckBox from '../../../shared/components/form/CheckBox';
import RenderFileInputField from '../../Form/components/FileUpload';

import { Field, reduxForm } from 'redux-form';
import Loader from '../../App/Loader';

const PreviewModal = ({type, emailTemplateData,closePreviewModal,isPreviewModal}) => {
    

    componentDidMount = async () => {
        let data = emailTemplateData;
        if (document.getElementById('templateText')) {
            document.getElementById('templateText').innerHTML = data['templateText']
        }
    }
    

        return (
            <div style={{ display: 'flex' }}>
                <Modal isOpen={isPreviewModal}
                    className={` modal-dialog--primary modal-dialog--header `}
                >
                    <ModalHeader className="modal__header viewModalHeader" >
                        Email Preview
                        <button className="lnr lnr-cross modal__close-btn" type="button"
                            onClick={closePreviewModal} />
                    </ModalHeader>
                    <ModalBody id='templateText'>
                    </ModalBody>
                </Modal>
            </div>
        );
    
}


export default PreviewModal;