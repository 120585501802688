import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Employees = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [rolesList, setRolesList] = useState([]);
  const [gotRoles, setgotRoles] = useState(false);
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    getRoleListFromServer();
    let screenPermissions = RolePermissions.screenPermissions("Employees");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };
  // componentDidMount = () => {
  //   //Screen permisions value can be edit,view, no view
  //   let screenPermissions = RolePermissions.screenPermissions('Test');
  //   if (screenPermissions) {
  //     this.setState({
  //       rolePermission: screenPermissions
  //     })
  //   }
  //   // this.getRoleListFromServer()
  // }
  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };
  const getRoleListFromServer = async () => {
    let filterCriteria = {},
      url;
    filterCriteria.direction = "asc";
    url = `roles?filter=${JSON.stringify(filterCriteria)}`;
    fetchMethodRequest("GET", url)
      .then(async (res) => {
        if (res && res.roles && res.roles.length > 0) {
          let rolesList = [];
          for (let obj of res.roles) {
            if (obj.role) rolesList.push({ value: obj.role, label: obj.role });
          }
          // await this.setState({ rolesList: rolesList,gotRoles:true });
          setRolesList(rolesList);
          setgotRoles(true);
        }
      })
      .catch((err) => {
        setgotRoles(true);
        return err;
      });
  };
  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        displayinregisterForm: true,
        disabled: true,
        globalSearchField: "true",
        isBulkUploadField: true,
        show: true,
        field: "name",
        addFormOrder: 1,
        editFormOrder: 1,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        id: "email",
        width: 150,
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        displayinregisterForm: true,
        isBulkUploadField: true,
        disabled: true,
        show: true,
        globalSearchField: "true",
        required: true,
        isFieldRequired: "true",
        field: "email",
        header: "Email",
        addFormOrder: 2,
        editFormOrder: 2,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        type: "textarea",
        placeholder: "Address",
        label: "Address",
        id: "address",
        width: 180,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        show: true,
        disabled: true,
        globalSearchField: "true",
        isBulkUploadField: true,
        field: "address",
        header: "Address",
        addFormOrder: 3,
        editFormOrder: 3,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "role",
        type: "dropDown",
        placeholder: "Role",
        label: "Role",
        header: "Role",
        derivedValue: "role=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        options: [],
        textAlign: "Center",
        show: true,
        disabled: true,
        field: "role",
        mobile: true,
        globalSearchField: "true",
        isBulkUploadField: true,
        isFieldRequired: "true",
        required: true,
        displayInSettings: true,
        fieldType: "dropDown",
        addFormOrder: 4,
        editFormOrder: 4,
        tDisplay: true,
      },
      {
        name: "phone",
        type: "text",
        placeholder: "Phone",
        label: "Phone",
        id: "phone",
        width: 110,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        displayinregisterForm: true,
        isBulkUploadField: true,
        show: true,
        disabled: true,
        field: "phone",
        header: "Phone",
        addFormOrder: 5,
        editFormOrder: 5,
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "reportingTo",
        type: "relateAutoComplete",
        placeholder: "ReportingTo",
        label: "ReportingTo",
        header: "ReportingTo",
        derivedValue: "reportingTo=undefined",
        actions: [],
        actionsNumber: [],
        id: "reportingTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: "employee",
        searchField: "name",
        fieldType: "relateAutoComplete",
        controllerId: 1001,
        searchApi: "employees",
        width: 110,
        textAlign: "Center",
        show: true,
        disabled: true,
        field: "reportingTo",
        addFormOrder: 6,
        editFormOrder: 6,
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "password",
        type: "password",
        placeholder: "password",
        label: "password",
        id: "password",
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "false",
        controllerName: null,
        textAlign: "Center",
        show: false,
        displayInSettings: false,
        disabled: true,
        isFieldRequired: "true",
        required: true,
        field: "password",
        header: "password",
        addFormOrder: 7,
        editFormOrder: 7,
        globalSearchField: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        isBulkUploadField: false,
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        addFormOrder: 10,
        editFormOrder: 10,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        isBulkUploadField: false,
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        addFormOrder: 11,
        editFormOrder: 11,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        displayinregisterForm: true,
        disabled: true,
        globalSearchField: "true",
        isBulkUploadField: true,
        show: true,
        addFormOrder: 1,
        editFormOrder: 1,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        id: "email",
        width: 150,
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        controllerName: null,
        displayinregisterForm: true,
        isBulkUploadField: true,
        disabled: true,
        show: true,
        globalSearchField: "true",
        required: true,
        isFieldRequired: "true",
        addFormOrder: 2,
        editFormOrder: 2,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: true,
      },
      {
        name: "address",
        type: "textarea",
        placeholder: "Address",
        label: "Address",
        id: "address",
        width: 180,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        show: true,
        disabled: true,
        globalSearchField: "true",
        isBulkUploadField: true,
        addFormOrder: 3,
        editFormOrder: 3,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "role",
        type: "dropDown",
        placeholder: "Role",
        label: "Role",
        derivedValue: "role=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        options: [],
        show: true,
        disabled: true,
        mobile: true,
        globalSearchField: "true",
        isBulkUploadField: true,
        isFieldRequired: "true",
        required: true,
        displayInSettings: true,
        fieldType: "dropDown",
        addFormOrder: 4,
        editFormOrder: 4,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phone",
        type: "text",
        placeholder: "Phone",
        label: "Phone",
        id: "phone",
        width: 110,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        displayinregisterForm: true,
        isBulkUploadField: true,
        show: true,
        disabled: true,
        addFormOrder: 5,
        editFormOrder: 5,
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "reportingTo",
        type: "relateAutoComplete",
        placeholder: "ReportingTo",
        label: "ReportingTo",
        derivedValue: "reportingTo=undefined",
        actions: [],
        actionsNumber: [],
        id: "reportingTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: "employee",
        searchField: "name",
        fieldType: "relateAutoComplete",
        controllerId: 1001,
        searchApi: "employees",
        width: 110,
        show: true,
        disabled: true,
        addFormOrder: 6,
        editFormOrder: 6,
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "password",
        type: "password",
        placeholder: "password",
        label: "password",
        id: "password",
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "false",
        controllerName: null,
        show: true,
        displayInSettings: false,
        disabled: true,
        isFieldRequired: "true",
        required: true,
        addFormOrder: 7,
        editFormOrder: 7,
        globalSearchField: "false",
        mobile: true,
        isAddFormHidden: false,
        isEditFormHidden: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        isBulkUploadField: false,
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        addFormOrder: 10,
        editFormOrder: 10,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        isBulkUploadField: false,
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        addFormOrder: 11,
        editFormOrder: 11,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        displayinregisterForm: true,
        disabled: true,
        globalSearchField: "true",
        isBulkUploadField: true,
        show: true,
        field: "name",
        addFormOrder: 1,
        editFormOrder: 1,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        id: "email",
        width: 150,
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        displayinregisterForm: true,
        isBulkUploadField: true,
        disabled: true,
        show: true,
        globalSearchField: "true",
        required: true,
        isFieldRequired: "true",
        field: "email",
        header: "Email",
        addFormOrder: 2,
        editFormOrder: 2,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        type: "textarea",
        placeholder: "Address",
        label: "Address",
        id: "address",
        width: 180,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        show: true,
        disabled: true,
        globalSearchField: "true",
        isBulkUploadField: true,
        field: "address",
        header: "Address",
        addFormOrder: 3,
        editFormOrder: 3,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "role",
        type: "dropDown",
        placeholder: "Role",
        label: "Role",
        header: "Role",
        derivedValue: "role=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        options: [],
        textAlign: "Center",
        show: true,
        disabled: true,
        field: "role",
        mobile: true,
        globalSearchField: "true",
        isBulkUploadField: true,
        isFieldRequired: "true",
        required: true,
        displayInSettings: true,
        fieldType: "dropDown",
        addFormOrder: 4,
        editFormOrder: 4,
        tDisplay: true,
      },
      {
        name: "phone",
        type: "text",
        placeholder: "Phone",
        label: "Phone",
        id: "phone",
        width: 110,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        displayinregisterForm: true,
        isBulkUploadField: true,
        show: true,
        disabled: true,
        field: "phone",
        header: "Phone",
        addFormOrder: 5,
        editFormOrder: 5,
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "reportingTo",
        type: "relateAutoComplete",
        placeholder: "ReportingTo",
        label: "ReportingTo",
        header: "ReportingTo",
        derivedValue: "reportingTo=undefined",
        actions: [],
        actionsNumber: [],
        id: "reportingTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: "employee",
        searchField: "name",
        fieldType: "relateAutoComplete",
        controllerId: 1001,
        searchApi: "employees",
        width: 110,
        textAlign: "Center",
        show: true,
        disabled: true,
        field: "reportingTo",
        addFormOrder: 6,
        editFormOrder: 6,
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "password",
        type: "password",
        placeholder: "password",
        label: "password",
        id: "password",
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "false",
        controllerName: null,
        textAlign: "Center",
        show: false,
        displayInSettings: false,
        disabled: true,
        isFieldRequired: "true",
        required: true,
        field: "password",
        header: "password",
        addFormOrder: 7,
        editFormOrder: 7,
        globalSearchField: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        isBulkUploadField: false,
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        addFormOrder: 10,
        editFormOrder: 10,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        isBulkUploadField: false,
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        addFormOrder: 11,
        editFormOrder: 11,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };
  // submit1 = item => {
  //   let x = employeesFields
  //   let objemployees = {}

  //   objemployees["_id"] = item[0]["_id"]
  //   for (let x2 of x) {
  //     objemployees[x2] = item[0][x2]
  //   }
  //   let formFields = this.getFormFields()

  //   formFields = formFields.filter(y => x.includes(y.name))

  //   this.setState({
  //     openNewUserModal: true,
  //     item: objemployees,
  //     newFormFields: formFields
  //   });
  // }

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };
  const getChangedRoleOptions = () => {
    let fields = getFormFields();
    if (fields && fields.length > 0) {
      for (let obj of fields) {
        if (obj.name == "role") {
          obj.options = rolesList && rolesList.length > 0 ? rolesList : [];
        }
      }
    }
    return fields;
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.employees}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {gotRoles && (
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getChangedRoleOptions}
          // MobileTableFields={this.getMobileTableFields}
          // getTableFields={this.getTableFields}
          // formFields={this.getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          // globalSearch={'Display Name/Email'}
          // type='Employees'
          // apiUrl={apiCalls.Employees}

          // exportRequried={true}

          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={undefined}
          searchInDateRangeField={"undefined"}
          sampleFilePath={filePath.employees}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          globalSearch={"name/email/address/role"}
          displayName="Employees"
          type="Employees"
          routeTo={apiCalls.employees}
          sideFormLeftOrRight=""
          displayViewOfForm="screen"
          apiResponseKey={apiCalls.employees?.toLowerCase()}
          apiUrl={apiCalls.employees}
          selectedId={params.id}
        />
      )}
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="employees"
          apiUrl={apiCalls.employees}
        />
      ) : null}
    </span>
  );
};

export default Employees;
