import React, { useState } from 'react';
import DoInputField from '../../../Form/Fields/DoInputField';
import { Button } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import fetchMethodRequest from '../../../../config/service';
import config from '../../../../config/config';
import { Navigate } from 'react-router-dom';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

const schema = yup.object().shape({
    otp: yup.string().required('Otp is required')
        .min(6, 'Otp must be at least 6 characters')
        .max(6, 'Otp must be at most 6 characters')
});

const VerifyAuthenticator = () => {
    const [isLoginSuccess, setIsLoginSuccess] = useState(false);

    let subHeader = 'Link your authentication app to your account by scanning the QR code. If you do not have an authentication app on your device, you will need to download one such as Authenticator, Authy, etc.'
    let details = JSON.parse(localStorage.getItem('authenticatorDetails'))
    let loggedEmployee = JSON.parse(localStorage.getItem('employee'));

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const sendOTP = (values) => {
        if (values) {
            const obj = {
                otp: values.otp,
                email: loggedEmployee?.email,
                entityType: config.entityType,
                type: 'Authenticator'
            };

            // appService.loaderStatus('block');
            return fetchMethodRequest('POST', 'auth/verifyAuthenticatorOTP', obj)
                .then(res => {
                    if (res && res.respMessage) {
                        if (res.accessToken && res.details) {
                            const { details, accessToken } = res;
                            const employeeDetails = {
                                ...details,
                                accessToken: accessToken
                            }
                            // setting of token and logged in employee details to localstorage.
                            localStorage.setItem('loginCredentials', JSON.stringify(employeeDetails));
                            if (employeeDetails.rolePermissions) {
                                localStorage.setItem('rolePermissions', JSON.stringify(employeeDetails.rolePermissions));
                            }
                            showToasterMessage(res.respMessage, 'success');
                            setTimeout(() => {
                                setIsLoginSuccess(true);
                            }, 200);
                            setTimeout(() => {
                                localStorage.removeItem('authenticatorDetails');
                            }, 500);
                        }
                    } else if (res && res.errorMessage) {
                        showToasterMessage(res.errorMessage, 'error');
                    }
                })
                .catch(error => {
                    showToasterMessage('An error occurred while sending OTP');
                });
        }
    };

    return (
        <div className="login-container">
            <div className="login4">
                <div className="card">
                    <div className="card-body p-4">

                        <div className="container-fluid">
                            <h4 className="text-center header-message">Two-Factor Authentication</h4>
                            <p>{subHeader}</p>
                        </div>
                        <div className="text-center w-100 pb-2">
                            <img src={details?.qrCode} alt="QR Code" />
                        </div>
                        {/* <div className="text-center pb-3">
                            <span>SECRET KEY:</span>&nbsp;&nbsp;&nbsp;<b>{details?.base32Secrect}</b>
                        </div> */}
                        <div className="text-center mb-3">
                            <p>Check your authentication app for a 6-digit authentication code.</p>
                        </div>
                        <div className="w-100">
                            <form onSubmit={handleSubmit(sendOTP)} className="text-left">
                                <div className="mb-3">
                                    <Controller
                                        name="otp"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <DoInputField
                                                input={field}
                                                id={field.id}
                                                field={field}
                                                name={field.name}
                                                type="text"
                                                fieldState={fieldState}
                                                errors={errors}
                                                placeholder="otp"
                                                style={{ width: '23rem' }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="account__btns">
                                    <Button className="ml-auto" style={{ width: '100%' }}
                                        color="primary" type='submit'>
                                        Sign in
                                    </Button>
                                </div>
                                <div className="text-left">
                                    <a href="/two_step_verification">Back to verification options...</a>
                                </div>

                                {isLoginSuccess ? <Navigate to="/employees" /> : null}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default VerifyAuthenticator;
