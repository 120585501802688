import { CKEditor } from '@ckeditor/ckeditor5-react';
// import Editor from 'ckeditor5-custom-build/build/ckeditor';
// import Editor from 'ckeditor5-custom-build'
// import Editor from '../../../../ckeditor/build/ckeditor.js'


const DoEditor = ({ field, errors }) => {

    return (
        
        <CKEditor            
            editor={"editor"}
          
            data={field.value ? field.value : 'Type here'}
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                field.onChange(data);                
            } }
        />
        
    )

}
export default DoEditor;