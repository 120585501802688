const commonSampleFilePath = "http://localhost:3000/images/sample";

const configSampleFiles = {
  employees:
    "http://api.mls.dosystemsinc.com/images/sample_employees_bulk_upload_file.csv",
  activities:
    "http://api.mls.dosystemsinc.com/images/sample_activities_bulk_upload_file.csv",
  emailtemplates:
    "http://api.mls.dosystemsinc.com/images/sample_emailtemplates_bulk_upload_file.csv",
  roles:
    "http://api.mls.dosystemsinc.com/images/sample_roles_bulk_upload_file.csv",
  uploadhistories:
    "http://api.mls.dosystemsinc.com/images/sample_uploadhistories_bulk_upload_file.csv",
  emailstatuses:
    "http://api.mls.dosystemsinc.com/images/sample_emailstatuses_bulk_upload_file.csv",
  myproperties:
    "http://api.mls.dosystemsinc.com/images/sample_myproperties_bulk_upload_file.csv",
  clients:
    "http://api.mls.dosystemsinc.com/images/sample_clients_bulk_upload_file.csv",
  agents:
    "http://api.mls.dosystemsinc.com/images/sample_agents_bulk_upload_file.csv",
};
export default configSampleFiles;
