import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DoCheckboxField from '../../Form/Fields/DoCheckboxField';
import { useForm, Controller } from 'react-hook-form';

const ExportToCsvModal = (props) => {

    const [csvOptions, setCsvOptions] = useState([]);

    let {
        handleSubmit,
        setValue,
        formState: { errors },
        control,
    } = useForm();

    useEffect(() => {
        const options = [];
        props.tableFields.forEach(col => {
            if (col.field && !['Actions', 'Check Box', 'Sno'].includes(col.field)) {
                options.push({ key: col.field, label: col.header, value: col.field });
                setValue(col.field, true);
            }
        });
        setCsvOptions(options);
    }, []);

    const onSubmit = (data) => {
        props.checkedFields(data);
    }

    return (
        <Modal isOpen={props.openExportToCsvModal}
            className={`modal-dialog-centered modal-dialog--primary modal-dialog--header `}>

            <ModalHeader className="modal__header">
                <button className="lnr lnr-cross modal__close-btn" type="button"
                    onClick={() => props.closeExportToCsvModal() && props.reload()} />
                <p className="bold-text  modal__title"> {'Select The Export Fields'} </p>
            </ModalHeader>
            <ModalBody>

                <div className="col-sm-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            {csvOptions.map((opt) => (
                                <div key={opt.key} className="col-md-4 mb-3">
                                    <Controller
                                        name={opt.key}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <DoCheckboxField
                                                input={field}
                                                name={field.name}
                                                field={field}
                                                fieldState={fieldState}
                                            />)}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className='row mt-3'>
                            <div className='col-sm-12 text-center'>
                                <Button color='primary' outline
                                    onClick={() => props.closeExportToCsvModal() && props.reload()}>{'Close'}</Button>
                                <Button color='primary' type="submit"
                                >{'Export Selected Fields'}</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </ModalBody>

        </Modal >
    );

}

export default ExportToCsvModal;