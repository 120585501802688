import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const SidebarContent = (props) => {
  const propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  
  
  const hideSidebar = () => {
    const { onClick } = props;
    onClick();
  };

 
 const getIcon = (menu) => {    
    if (menu.iconType) {
      if (menu.iconType === 'icon') {
        return (menu.iconName ? menu.iconName : 'user');
      } else if (menu.iconType === 'letters') {
        return (menu.iconLetters ? menu.iconLetters : menu.displayTitle.substring(0, 2));
      }
    }else {
      return menu.displayTitle.substring(0, 2);
    }
  }

 
    const { menuList } = props;
    
 
    return (
      <div className="sidebar__content"
        style={menuList && menuList.length > 14 ? { "height": 40 * menuList.length } : { "height": 'calc(100vh - 60px)' }}
      >
        <ul className="sidebar__block">
          {menuList && menuList.length > 0
            ? menuList.map((item, index) => {
              if (item.submenus && item.submenus.length > 0) {
                return (
                  <SidebarCategory
                    key={index}
                    title={item.displayTitle == 'Settings' ? 'Settings Menu' : item.displayTitle}
                    icon={item.iconLetters}
                    iconType={item.iconType}
                  >
                    
                    {item.submenus.map((sitem, sindex) => {
                      return (
                        
                        <SidebarLink key={sindex}
                          title={sitem.displayTitle}
                          icon={getIcon(sitem)}
                          onClick={hideSidebar}
                          iconType={sitem.iconType}
                          route={sitem.route} 
                          isSubmenu={true}
                          />
                      )
                    })}
                  </SidebarCategory>
                )
              } else {
                return (
                  <SidebarLink
                    key={index}
                    icon={getIcon(item)}
                    title={item.displayTitle}
                    route={item.route}
                    onClick={hideSidebar}
                    iconType={item.iconType}
                    isSubmenu={false}
                  />
                );
              }
            })
            : null}
        </ul>
      </div>
    );
  }


export default SidebarContent;

