import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';
import { MultiSelect } from 'primereact/multiselect';
import fetchMethodRequest from '../../../config/service';
import moment from 'moment';

const DataTableEditor = (props) => {

    const [filteredSuggestions, setFilteredSuggestions] = useState([]);

    const filterCriteria = { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' };
    const options = props.options;
    const item = props.item ? props.item : {};

    let getDefault = (options) => {
        return <InputText type={item.type} value={options.value} placeholder={item.placeholder}
            onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const dropDownEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={item.options}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder={item.placeholder}
            />
        );
    };

    const dropDownMultiSelectEditor = (options) => {
        return (
            <div className='inline-multi-select'>
                <MultiSelect value={options.value}
                    onChange={(e) => options.editorCallback(e.value)}
                    options={item.options}
                    optionLabel={item.optionLabel}
                    placeholder={item.placeholder}
                />
            </div>
        )
    }

    const autoCompleteEditor = (options) => {

        const getAllSuggestions = (event) => {
            if (event && event.query) {
                filterCriteria['criteria'].push({
                    "key": item.searchField,
                    "value": event.query,
                    "type": "regexOr"
                });
            };
            let apiUrl = item.searchApi?.toLowerCase();
            let url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
            return fetchMethodRequest('GET', url)
                .then((response) => {
                    if (response) {
                        let dropdownData = response[apiUrl];
                        if (dropdownData && dropdownData.length == 0) {
                            setFilteredSuggestions([]);
                        } else {
                            setFilteredSuggestions(dropdownData);
                        }
                    }
                }).catch((err) => {
                    return err;
                });
        }

        return (
            <AutoComplete field="name" value={options.value} suggestions={filteredSuggestions} placeholder={item.placeholder}
                completeMethod={getAllSuggestions} onChange={(e) => options.editorCallback(e.value)} multiple={item.isMultiple} dropdown />
        )
    }

    const textAreaEditor = (options) => {
        return (
            <InputTextarea value={options.value} placeholder={item.placeholder} onChange={(e) => options.editorCallback(e.target.value)} rows={1} cols={30} />
        )
    }

    const dateEditor = (options) => {
        let formattedDate = options.value ? moment(options.value, 'DD-MM-YYYY').toDate() : null;
        return (
            <Calendar value={formattedDate} placeholder={item.placeholder} onChange={(e) => options.editorCallback(e.value)} dateFormat="dd-mm-yy" />
        )
    }

    const checkBoxEditor = (options) => {
        return (
            <Checkbox onChange={(e) => options.editorCallback(e.target.checked)} checked={options.value === 'true' || options.value === true}></Checkbox>
        )
    }

    const radioButtonEditor = (options) => {
        return (
            <div className="flex align-items-center">
                {
                    item.options.map((category) => {
                        return (
                            <div key={category.value} className="flex align-items-center">
                                <RadioButton inputId={category.value} name="category" value={category.value} onChange={(e) => options.editorCallback(e.target.value)} checked={options.value === category.value} />
                                <label htmlFor={category.key} className="ml-2">{category.label}</label>
                            </div>
                        );
                    })
                }
            </div>
        )
    }

    const fileUploadEditor = (options) => {

        const upload = (data) => {
            let url = `uploads/uploadAttachments?type=${item.imagePath.toLowerCase()}`
            fetchMethodRequest('POST', url, data, 'upload')
                .then(async (response) => {
                    if (response && response.respCode) {
                        let fileName = response?.fileName
                        options.editorCallback([fileName[0].name])
                        showToasterMessage(response.respMessage, 'success');
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }).catch((err) => {
                    return err;
                });
        }
        return (
            <FileUpload mode="basic" name="file" onSelect={upload} accept="*" maxFileSize={1000000} />
        )
    }

    const getText = (options) => {
        return <>{options.value}</>;
    };

    return (
        <div>
            {(item.show === false || item.isEditFormHidden === true || Object.keys(item).length === 0) ?
                getText(options) :
                item.type === 'dropDown' && item.isMultiSelect ? dropDownMultiSelectEditor(options) :
                (item.type === 'dropDown' || item.type === 'radio') ? dropDownEditor(options) :
                    item.type === 'relateAutoComplete' ? autoCompleteEditor(options) :
                        item.type === 'textarea' ? textAreaEditor(options) :
                            item.type === 'date' ? dateEditor(options) :
                                item.type === 'fileUpload' ? fileUploadEditor(options) :
                                    item.type === 'checkbox' ? checkBoxEditor(options) :
                                        // item.type === 'radio' ? radioButtonEditor(options) :
                                        getDefault(options)
            }
        </div>
    );
}
export default DataTableEditor;
