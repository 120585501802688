import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import config from '../../../config/config';
import configImages from '../../../config/configImages';

const FileGalleryView = ({ item, column, type }) => {
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentFileName, setCurrentFileName] = useState('');

    const isVideo = (fileName) => {
        const ext = fileName.split('.').pop().toLowerCase();
        return ['mp4', 'webm', 'ogg'].includes(ext);
    };

    const mediaFiles = item[column.field].map(file => ({
        original: `${config.imgUrl}${type.toLowerCase()}/${file}`,
        thumbnail: isVideo(file) ? configImages.vid : `${config.imgUrl}${type.toLowerCase()}/${file}`,
        fileName: file,
        renderItem: () => (
            isVideo(file) ? (
                <video controls className="gallery-media">
                    <source src={`${config.imgUrl}${type.toLowerCase()}/${file}`} type={`video/${file.split('.').pop().toLowerCase()}`} />
                    Your browser does not support the video tag.
                </video>
            ) : (
                <img src={`${config.imgUrl}${type.toLowerCase()}/${file}`} alt={file} className="gallery-media" />
            )
        )
    }));

    const openGallery = () => {
        setViewerIsOpen(true);
        if (mediaFiles.length > 0) {
            setCurrentFileName(mediaFiles[0].fileName);
        }
    };

    const closeGallery = () => {
        setViewerIsOpen(false);
    };

    const handleSlideChange = (index) => {
        setCurrentSlide(index);
        setCurrentFileName(mediaFiles[index].fileName);
    };

    return (
        <div className="FileGalleryView">
            <button onClick={openGallery}>
                {`View ${column.field}`}
            </button>
            {viewerIsOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeGallery}>&times;</span>
                        <div className="gallery-index">{currentSlide + 1} / {mediaFiles.length}</div>
                        <ImageGallery
                            items={mediaFiles}
                            showPlayButton={true}
                            showFullscreenButton={true}
                            slideInterval={3000}
                            showIndex={true}
                            startIndex={currentSlide}
                            onSlide={handleSlideChange}
                        />
                        <div className="file-name-display">{currentFileName}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileGalleryView;
