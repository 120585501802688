import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';
import { Link,Navigate } from 'react-router-dom';


import LogoutModal from '../../Cruds/CommonModals/LogoutModal';

const TopbarMenuLinks = (props) => {
  
  const [isLogout, setIsLogout] = useState(false);
  const [isLogoutSuccess, setIsLogoutSuccess] = useState(false);
  const [isOpenLogoutModal, setIsOpenLogoutModal] = useState(false);

  const propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  };
 
  const logout = async () => {
    props.toggleClose();
    setIsOpenLogoutModal(true);
  }

  // close delete modal
  const closeLogoutModal = (type) => {   
    setIsOpenLogoutModal(false);
    if (type == 'close') {    
      setIsLogoutSuccess(true)
    }
  }

  
    const { title, icon, path, toggleClose } = props;

    return (
      <div>
        {title == 'Log Out' ?
          <div className="topbar__link" style={{
            paddingBottom: '10px',
            paddingLeft: '23px', paddingTop: '10px'
          }}
            onClick={() => logout()}>
            <span className={`topbar__link-icon lnr lnr-${icon}`} />
            <span className="topbar__link-title">{title}</span>
          </div>
          :
          <Link className="topbar__link"
          to={{ pathname: path, state: { updateTopbarProfileData: props.updateTopbarProfileData } }}
          onClick={toggleClose}>
            <span className={`topbar__link-icon lnr lnr-${icon}`} />
            <p className="topbar__link-title">{title}</p>
          </Link>}

        {isLogoutSuccess ?
          <div>
            <Navigate to='/log_in' />
          </div> : null}

        {/* display modal for logout */}
        <LogoutModal openLogoutModal={isOpenLogoutModal}
          closeLogoutModal={closeLogoutModal} />
      </div>

    );
  
}
export default TopbarMenuLinks;
