import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Card, CardBody, Col, Container, Row, Table } from "reactstrap";

 const ViewModal = ({type,openViewModal,closeViewModal}) =>  {
  
  const [rowData,setRowData] = useState({}); 
 

  const getRowData = (rowData) => {
    setRowData(rowData);
    if (type == 'Teams') {
    }
  };


    return (
      <div style={{ display: 'flex' }}>
        <Modal isOpen={openViewModal}
          className={` modal-dialog--primary modal-dialog--header`}
        >
          <ModalHeader className="modal__header viewModalHeader" >
            {type} Details
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeViewModal} />
          </ModalHeader>
          <ModalBody>
            {rowData && rowData.length > 0 ?
              <div className="row form" >
                {rowData.map((item, i) => {
                  return (
                    item.value != "" || null ?
                      <div className="col-sm-6" key={i}>
                        <div className="row" style={{ margin: "auto" }}>
                          <div
                            className="col-sm-5"
                            style={{ textAlign: "left", padding: "5px 5px" }}>
                            <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
                              {item.label}
                            </span>
                          </div>

                          <div className="col-sm-7"
                            style={{ textAlign: "left", padding: "5px 0px" }}>
                            <span>{item.value}</span>
                          </div>
                        </div>
                      </div> : null
                  );
                })}
              </div>
              : null}
          </ModalBody>
   
        </Modal>

      </div >
    );

  }



export default ViewModal;
