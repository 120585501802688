import React, { PureComponent, useState } from 'react';
import {
    Card, CardBody, Col, Row, Button, CardImg, ButtonToolbar, Popover, PopoverBody, Modal, ModalHeader, ModalBody, ModalFooter, Badge
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const SessionExpiryModal = ({ closeSModal, isOpen, SOpen }) => {

    const [sessionExpired,setSessionExpired] = useState(false);
    const [date,setDate] = useState(new Date());
    const [pen,setPen] = useState(false); 
    const [sessionExpired1,setSessionExpired1] = useState(false);
    const [count,setCount] = useState(60);

    const redirectMethod = async () => {

        await this.setState({ SessionExpired: true })

    }
   const logoutYes = async () => {
        localStorage.removeItem('loginBody');
        localStorage.removeItem('loginCredentials');

        sessionStorage.clear()
        setSessionExpired1(true);
        setDate('');
        setCount(60)
    }
    const closeModal = async () => {
        setSessionExpired1(true);
        setDate('');
        setCount(60);
        setPen(false);
        closeSModal();
    }
    const logoutfunc = () => {
        localStorage.clear();
        sessionStorage.clear();
        setSessionExpired(true);
    }
   
       
        return (
            <div>

                <Modal isOpen={SOpen} centered={true}>

                    {!sessionExpired ? <ModalHeader className="bold-text" style={{ backgroundColor: '#0e4768', color: 'white', textAlign: 'center' }}  >
                        {"You're being timed out due to inactivity"}
                    </ModalHeader> : null}
                    <ModalBody className='content__modal'>
                        <h3 className="py-3">{'We’re Sorry'} </h3>
                        <h3 className="py-3">{'Your session has expired please login again'}</h3>

                        <Row className="pt-2 justify-content-center">
                            <Col sm={12} className="text-center">
                                {sessionExpired1 ? <Navigate to="/log_in" /> : null}
                                {sessionExpired ? <Button className="recipients-button large create btn-create " size="lg" type="button" onClick={logoutYes}>{'Okay'}</Button> : null}
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Link className="btn btn-primary" to="/log_in">{'Login again'}</Link>
                        </Row>
                    </ModalBody>
                </Modal>
            </div>
        )
    
}
export default SessionExpiryModal;
